<template>
  <div class="container">
    <template v-if="showDeclare">
      <div class="header">
        <van-icon v-if="data.declareState === 1" name="passed" />
        <van-icon v-else-if="data.declareState === 3" name="close" />
        <van-icon v-else name="info-o" />
        <p>{{ data.declareState | filterStatus }}</p>
      </div>
      <van-cell-group style="margin: 40px">
        <van-cell title="OL端末通関通番" :value="data.clientDeclareNo" />
        <van-cell title="OL端末処理通番" :value="data.clientOrderNo" />
        <van-cell title="検証機関" :value="data.verifyDepartment" />
        <van-cell
          title="検証機関のトランザクションシリアル番号"
          :value="data.verifyDepartmentTradeId"
        />
        <van-cell
          title="認証状態"
          :value="data.certCheckResult === 0 ? 'UNCHECKED' : 'DIFFERENT'"
        />
        <van-cell title="税関申告開始時間" :value="data.declareStartTime" />
        <van-cell
          title="税関申告最終修正時間"
          :value="data.declareUpdateTime"
        />
        <van-cell title="通関完了時間" :value="data.declareFinishTime" />
      </van-cell-group>
    </template>
    <van-cell-group v-else style="margin: 40px">
      <van-cell title="OL端末通関通番" :value="data.clientDeclareNo" />
      <van-cell title="OL端末処理通番" :value="data.clientOrderNo" />
      <van-cell title="税関コード" :value="customs" />
      <van-field
        v-model="mchCustomsNo"
        label="取引先税関届出番号"
        placeholder="请输入取引先税関届出番号"
        maxlength="50"
        input-align="right"
      />
      <van-button type="primary" block @click="getConsultPayment">
        Done
      </van-button>
    </van-cell-group>
  </div>
</template>

<script>
import { customsDeclare } from '@/apis'

export default {
  name: 'Confirmation',
  filters: {
    filterStatus(status) {
      if (status === 0) {
        return '未申告'
      } else if (status === 1) {
        return '申告成功'
      } else if (status === 2) {
        return '申告提出済'
      } else if (status === 3) {
        return '申告失敗'
      } else if (status === 4) {
        return '申告エラー'
      } else if (status === 7) {
        return '申告中'
      } else {
        return 'Unknow status'
      }
    },
  },
  data() {
    return {
      showDeclare: false,
      data: {
        clientDeclareNo: '',
        clientOrderNo: '',
        verifyDepartment: '',
        verifyDepartmentTradeId: '',
        declareState: '',
        certCheckResult: '',
        declareStartTime: '',
        declareUpdateTime: '',
        declareFinishTime: '',
      },
      customs: 'GUANGZHOU_ZS',
      mchCustomsNo: '3701360CMW',
    }
  },
  created() {
    this.data.clientOrderNo = this.$route.query.clientOrderNo
    this.data.clientDeclareNo = this.$route.query.clientDeclareNo
  },
  methods: {
    getConsultPayment() {
      const { customs, mchCustomsNo } = this
      const { clientOrderNo, clientDeclareNo } = this.$route.query
      customsDeclare({
        clientDeclareNo,
        clientOrderNo,
        customs,
        mchCustomsNo,
      }).then((data) => {
        this.data = data
        this.showDeclare = true
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 1080px;
  margin: 60px auto;
  padding: 40px 0;
  background: #fff;
}
.header {
  text-align: center;
  .van-icon {
    font-size: 60px;
    color: #010101;
  }
  h4 {
    margin: 10px 0;
    font-size: 28px;
  }
}
.van-button {
  width: 220px;
  margin: 40px auto;
}
.van-cell__value {
  font-weight: 600;
  color: #010101;
}
.van-cell {
  /deep/ .van-field__label {
    width: auto;
  }
}
</style>
